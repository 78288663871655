import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Button } from '../../globals';

export default function PopupModalBase({
  style,
  footer,
  confirmText,
  cancelText,
  children,
  title,
  visible,
  confirmLoading,
  onConfirmClick,
  onCancelClick,
  width,
  templates,
}) {
  // Definitions
  const { t } = useTranslation();

  // Render
  return (
    <Container
      style={style}
      footer={
        footer !== undefined
          ? footer
          : (onConfirmClick || onCancelClick) && (
              <Footer>
                {onConfirmClick && (
                  <Button key="confirm" onClick={onConfirmClick} templates={['colored']}>
                    {confirmText ?? t('Confirm')}
                  </Button>
                )}
                {onCancelClick && (
                  <Button key="cancel" onClick={onCancelClick} templates={['colored']}>
                    {cancelText ?? t('Cancel')}
                  </Button>
                )}
              </Footer>
            )
      }
      onCancel={onCancelClick}
      title={title}
      visible={visible ?? false}
      confirmLoading={confirmLoading !== undefined ? !confirmLoading : false}
      width={width}
      templates={templates}
    >
      {children}
    </Container>
  );
}

const Container = styled(Modal)`
  .ant-modal-content {
    border-radius: ${(x) => x.theme.global.borderRadius};
    .ant-modal-header {
      border-radius: ${(x) => x.theme.global.borderRadius} ${(x) => x.theme.global.borderRadius} 0 0;
      border-bottom: 1px solid ${(x) => x.theme.colors.antiFlashWhiteDark};
    }
  }

  ${({ templates }) =>
    templates?.includes('colored') &&
    css`
      .ant-modal-content {
        .ant-modal-close {
          color: ${(x) => x.theme.colors.white};
        }
        .ant-modal-header {
          background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
          .ant-modal-title {
            color: ${(x) => x.theme.colors.white};
          }
        }
      }
    `}

  ${({ templates }) =>
    templates?.includes('preview') &&
    css`
      .ant-modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    `}

  ${({ templates }) =>
    templates?.includes('document-preview') &&
    css`
      width: 100% !important;
      height: calc(100vh - 20px);
      top: 20px;
      .ant-modal-content {
        height: 100%;
        .ant-modal-body {
          height: 100%;
        }

        .ant-modal-close {
          background-color: ${(x) => x.theme.colors.mediumSpringGreen};
          border-radius: 50px;
          color: ${(x) => x.theme.colors.white};
          top: -10px;
          right: -10px;
        }
      }
    `}
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 0px -8px;

  .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin: 0px;
  }
`;
