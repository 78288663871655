import { Col, Row } from 'antd';
import { saveAs } from 'file-saver';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import styled, { css } from 'styled-components';
import { storageDelete, storageDownload, storageList } from '../../../services/api';
import { getStorageUrl } from '../../../services/config';
import { getFileExtension, getFileName, isImage } from '../../../utilies/fileHelper';
import { Button, FileBox, Input, Modal } from '../../globals';
import StorageUpload from './StorageUpload';

export default function StorageFiles({ path, onSelectImageClick, refresh }) {
  // Definitions
  const { t } = useTranslation();

  const [data, setData] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [previewFile, setPreviewFile] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [uploadPanelVisible, setUploadPanelVisible] = useState(false);
  const [searchValue, setSearchValue] = useState(null);

  const [state, setState] = useState({ list: true, delete: true });

  // Hooks
  useEffect(() => {
    load();
  }, [path]);

  // Functions
  const load = async () => {
    setState((x) => ({ ...x, list: false }));

    storageList({ path: path, isDeleted: false }, (status, res) => {
      if (status) {
        let result = res.data.map((x) => ({
          id: x.id,
          name: getFileName(x.name),
          fullName: x.name,
          uid: x.id,
          path: x.path,
          url: `${getStorageUrl()}/${x.path}`,
          status: 'done',
          extension: getFileExtension(x.name),
          isImage: isImage(x.name),
        }));

        setData(result);
        setDataList(result);
      }

      setState((x) => ({ ...x, list: true }));
    });
  };

  // Events
  const onSearchChange = (event) => {
    let value = event.target.value;
    if (value !== '') {
      setData(dataList.filter((x) => x.name.includes(value.toLocaleLowerCase())));
    } else {
      setData(dataList);
    }
    setSearchValue(value);
  };

  const onPreviewClick = async (file) => {
    setPreviewFile(file);
    setPreviewVisible(true);
  };

  const onDownloadClick = async (file) => {
    await storageDownload({ path: file.path }, (status, res, fileName) => {
      if (status) {
        saveAs(res, fileName);
      }
    });
  };

  const onDeleteClick = async (file) => {
    setState((x) => ({ ...x, delete: false }));

    await storageDelete(file, (status, res) => {
      if (status) {
        load();
      }

      setState((x) => ({ ...x, delete: true }));
    });
  };

  const onRefresh = () => {
    load();
    refresh();
  };

  return (
    <Container ready={state.list}>
      <Row gutter={8}>
        <Col xs={{ span: 24 }} lg={{ span: 20 }}>
          <Input.Search
            placeholder={t('Search')}
            onChange={onSearchChange}
            disabled={state.list !== undefined ? !state.list : false}
            loading={state.list !== undefined ? !state.list : false}
            templates={['storage']}
            value={searchValue}
          />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 4 }}>
          <Button
            style={{ marginLeft: 8, width: 'calc(100% - 8px)' }}
            ready={state.list}
            block={true}
            onClick={() => setUploadPanelVisible(true)}
            templates={['primary']}
          >
            <FaPlus /> {t('AddNew')}
          </Button>
        </Col>
      </Row>
      <FileList>
        {data?.map((x, i) => (
          <FileBox
            key={`file-${i}`}
            file={x}
            onSelectClick={onSelectImageClick}
            onPreviewClick={onPreviewClick}
            onDownloadClick={onDownloadClick}
            onDeleteClick={onDeleteClick}
          />
        ))}
      </FileList>

      <PreviewModal
        title={previewFile?.isImage ? t('Preview') : ''}
        visible={previewVisible}
        onCancelClick={() => setPreviewVisible(false)}
        footer={null}
        templates={[previewFile?.isImage ? 'preview' : 'document-preview', 'colored']}
      >
        {previewFile?.isImage ? <PreviewImage src={previewFile?.url} /> : <PreviewDocument src={previewFile?.url} />}
      </PreviewModal>

      <StorageUpload path={path} visibility={uploadPanelVisible} setVisibility={setUploadPanelVisible} refresh={onRefresh} />
    </Container>
  );
}

const Container = styled.div`
  ${({ ready }) =>
    !ready &&
    css`
      display: none;
    `}
`;

const FileList = styled.div`
  > * {
    float: left;
    margin-bottom: 8px;
    margin-right: 8px;
  }
`;

const PreviewModal = styled(Modal)`
  .ant-modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const PreviewImage = styled.img`
  height: 100%;
  width: 100%;
  max-height: 400px;
  max-width: 400px;
`;

const PreviewDocument = styled.iframe`
  height: 100%;
  width: 100%;
`;
