import { Form } from 'antd';
import { detectIncognito } from 'detectincognitojs';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router';
import replacer from 'react-string-replace';
import styled from 'styled-components';
import { Button, Card, Checkbox, Element, Global, Link, Modal } from '../../components/globals';
import AskForHelp from '../../components/pages/layout/AskForHelp';
import { ContentGroupType, ContentType, CountryTypes, LanguageTypes, UserLoginEmailInputs } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import i18n from '../../i18n';
import { contentStaticDetail, login, loginApprove, loginEmail, loginGigyaLogin } from '../../services/api';
import { getItem, getLocale, setItem } from '../../services/cache';
import { cleanParameter, getLanguageType, getParameters, replaceParameters } from '../../utilies/localization';

export default function Login() {
  // Definitions
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [emailFormRef] = Form.useForm();
  const [smsFormRef] = Form.useForm();
  const [, setUser] = useCacheUser();
  const maintenanceText = t('MaintenanceMessage');

  const [contentData, setContentData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [locationData, setLocationData] = useState(null);
  const [hashDetail, setHashDetail] = useState(null);
  const [isIncognito, setIsIncognito] = useState(null);
  const [maintenanceMessage, setMaintenanceMessage] = useState(null);

  const [rememberMe, setRememberMe] = useState(false);

  const [panelVisibility, setPanelVisibility] = useState(false);
  const [contentPanelVisibility, setContentPanelVisibility] = useState(false);
  const [helpPanelVisibility, setHelpPanelVisibility] = useState(false);
  const [secondPanelVisibility, setSecondPanelVisibility] = useState(false);

  const [pageState, setPageState] = useState({ form: false, country: false });

  const [contentFilter, setContentFilter] = useState({ type: ContentType.Static });

  // Hooks
  useEffect(() => {
    let locale = getLocale();
    moment.locale(locale);
    i18n.changeLanguage(locale);
    setPageState((x) => ({ ...x, form: true }));

    let email = getItem('e');
    if (email) {
      emailFormRef.setFieldsValue({ email });
      setRememberMe(true);
    }

    let phone = getItem('p');
    if (phone) {
      smsFormRef.setFieldsValue({ phone });
      setRememberMe(true);
    }

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocationData({ latitude: position.coords.latitude, longitude: position.coords.longitude });
      });
    }

    maintenanceMessageCheck();
    thirdPartyCookieCheck();
  }, []);

  useEffect(() => {
    if (location.hash) {
      hashContent(location.hash);
    }
  }, [location]);

  // Functions
  const maintenanceMessageCheck = async () => {
    if (maintenanceText !== 'MaintenanceMessage') {
      let parameters = getParameters(maintenanceText);
      if (parameters?.length > 0) {
        let dateParameter = cleanParameter(parameters.shift());
        let date = moment(dateParameter, 'DD.MM.YYYY HH:mm').add(moment().utcOffset(), 'minutes');
        if (date.isValid()) {
          setMaintenanceMessage(
            replaceParameters(maintenanceText, {
              [dateParameter]: date.format('llll'),
            })
          );
        }
      }
    }
  };

  const thirdPartyCookieCheck = async () => {
    var result = await detectIncognito();
    setIsIncognito(result.isPrivate);
  };

  const hashContent = async (hash) => {
    await contentStaticDetail({ type: ContentType.Static, id: hash.slice(1) }, (status, res) => {
      setContentPanelVisibility(false);
      setSecondPanelVisibility(true);
      setHashDetail(res.data);
    });
  };

  const loginUser = (token) => {
    let result = jwtDecode(token);
    if (result.v === 0) {
      setUserData({ ...result, token });
      setPageState((x) => ({ ...x, form: true }));
      setPanelVisibility(true);
      setContentFilter((x) => ({ ...x, country: result.c }));
    } else {
      setUser(token);
      window.location = location?.state?.path ?? '/';
    }
  };

  // Events
  const onEmailFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    if (rememberMe) {
      setItem('e', model.email);
    }

    if (pageState.country) {
      await loginEmail({ ...model, ...locationData, timeOffset: moment().utcOffset() }, (status, res) => {
        if (status) {
          loginUser(res.data);
        } else {
          setPageState((x) => ({ ...x, form: true }));
        }
      });
    } else {
      await login(model, async (status, res) => {
        if (status) {
          let country = CountryTypes.find((x) => x.value === res.data);
          setItem('c', country.value);

          let languageData = LanguageTypes.find((x) => x.value === country.data.language);

          setItem('lcl', languageData.data.locale);
          moment.locale(languageData.data.locale);
          i18n.changeLanguage(languageData.data.locale);

          if (country.data.saml) {
            await loginGigyaLogin({}, (samlStatus, samlRes) => {
              if (samlStatus) {
                window.location = samlRes.data;
              } else {
                setPageState((x) => ({ ...x, form: true }));
              }
            });
          } else {
            setPageState((x) => ({ ...x, form: true, country: true }));
          }
        } else {
          setPageState((x) => ({ ...x, form: true }));
        }
      });
    }
  };

  const onStaticClick = async (group) => {
    await contentStaticDetail({ ...contentFilter, group, language: getLanguageType(getLocale()) }, (status, res) => {
      if (status) {
        setContentPanelVisibility(true);
        setContentData(res.data);
      }
    });
  };

  const onApproveConfirmClick = async () => {
    setPageState((x) => ({ ...x, form: false }));

    await loginApprove({ ...userData, country: userData.c }, (status, res) => {
      if (status) {
        setUser(res.data);
        setPanelVisibility(false);
        window.location = location?.state?.from?.pathname ?? '/';
      } else {
        setPageState((x) => ({ ...x, form: true }));
      }
    });
  };

  const onCloseSecondPanel = async () => {
    setContentPanelVisibility(true);
    setSecondPanelVisibility(false);
    navigate(location.pathname);
  };

  // Components
  const ApprovePanel = () => {
    let text = t('UserApproveText');

    let replacements = [
      {
        key: '{Static.PrivacyPolicy}',
        value: (
          <TextLink key="privacy-policy" onClick={() => onStaticClick(ContentGroupType.PrivacyPolicy)}>
            {t('PrivacyPolicy')}
          </TextLink>
        ),
      },
      {
        key: '{Static.TermsOfUse}',
        value: (
          <TextLink key="terms-of-use" onClick={() => onStaticClick(ContentGroupType.TermsOfUse)}>
            {t('TermsOfUse')}
          </TextLink>
        ),
      },
      {
        key: '{Static.PersonalDataProcessing}',
        value: (
          <TextLink key="personal-data-processing" onClick={() => onStaticClick(ContentGroupType.PersonalDataProcessing)}>
            {t('PersonalDataProcessing')}
          </TextLink>
        ),
      },
    ];

    replacements.forEach((x) => {
      text = replacer(text, x.key, () => x.value);
    });

    return text;
  };

  // Render
  return (
    <Global>
      <Container>
        <Card ready={pageState.form} templates={['login']}>
          <Logo src="/assets/images/logo.png" />
          <Element ready={pageState.form} formRef={emailFormRef} onFinish={onEmailFinish} inputs={UserLoginEmailInputs({ password: pageState.country })} columnSize={24}>
            <ActionPanel>
              <Checkbox checked={rememberMe} onClick={() => setRememberMe(!rememberMe)} templates={['bold']}>
                {t('RememberMe')}
              </Checkbox>
              <Link to="/user/forgot-password">{t('ForgotPassword')}</Link>
            </ActionPanel>

            <Button htmlType="submit" templates={['colored']} ready={pageState.form} block={true}>
              {t('Login')}
            </Button>
          </Element>

          <HelpButton onClick={() => setHelpPanelVisibility(true)}>{t('AskForHelp')}</HelpButton>
        </Card>

        <Modal
          width={320}
          title={t('ApproveUser')}
          visible={panelVisibility}
          onCancelClick={() => setPanelVisibility(false)}
          confirmText={t('Confirm')}
          onConfirmClick={onApproveConfirmClick}
          templates={['colored']}
        >
          <ApprovePanel />
        </Modal>

        {contentData && (
          <Modal
            width="90%"
            title={t(contentData.title)}
            visible={contentPanelVisibility}
            onCancelClick={() => setContentPanelVisibility(false)}
            footer={null}
            templates={['colored']}
          >
            <ContentBody dangerouslySetInnerHTML={{ __html: contentData.body }}></ContentBody>
          </Modal>
        )}

        <Modal width="90%" title={t(hashDetail?.title)} visible={secondPanelVisibility} onCancelClick={onCloseSecondPanel} footer={null} templates={['colored']}>
          {hashDetail && <Body dangerouslySetInnerHTML={{ __html: hashDetail.body }} />}
        </Modal>
      </Container>

      <AskForHelp isExternal={true} visibility={helpPanelVisibility} setVisibility={setHelpPanelVisibility} />

      {isIncognito && <IncognitoMessage>{t('IncognitoCookieMessage')}</IncognitoMessage>}

      {maintenanceMessage && <MaintenanceMessage>{maintenanceMessage}</MaintenanceMessage>}
    </Global>
  );
}

const TextLink = styled.a`
  color: blue;
`;

const Container = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  min-height: 100vh;
`;

const Logo = styled.img`
  width: 150px;
  margin: 10px auto 20px;
  display: block;
`;

const ActionPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const ContentBody = styled.div``;

const HelpButton = styled.div`
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  margin-top: 20px;
`;

const Body = styled.div``;

const IncognitoMessage = styled.div`
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(x) => x.theme.colors.rustyRed};
  color: ${(x) => x.theme.colors.white};
  padding: 15px;
  border-radius: 30px;
  text-align: center;
  width: 100%;
  max-width: 500px;
`;

const MaintenanceMessage = styled.div`
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${(x) => x.theme.colors.rustyRed};
  color: ${(x) => x.theme.colors.white};
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  width: 100%;
  max-width: 500px;
`;
