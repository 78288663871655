import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsQuestionDiamondFill } from 'react-icons/bs';
import { FaBookReader, FaPodcast } from 'react-icons/fa';
import { GrArticle } from 'react-icons/gr';
import { HiDocumentSearch } from 'react-icons/hi';
import { RiSurveyFill, RiVideoFill } from 'react-icons/ri';
import { useNavigate, useParams } from 'react-router';
import { Card, Page } from '../../components/globals';
import ContentItemList from '../../components/pages/content/ContentItemList';
import { ContentType, ContentTypes } from '../../constants';
import { useCacheUser } from '../../hooks/useCacheUser';
import { contentDetailGlobal, contentDetailView, contentUserDetail } from '../../services/api';
import { getLocale } from '../../services/cache';
import { getLanguageType } from '../../utilies/localization';

export default function ContentDetail() {
  // Definitions
  const { t } = useTranslation();
  const { url } = useParams();
  const navigate = useNavigate();
  const [user] = useCacheUser();

  const [data, setData] = useState(null);

  const [pageState, setPageState] = useState({ detail: false });

  const [filter] = useState({
    url,
    pageNumber: 1,
    dataLimit: 3,
    country: user.c,
    userCountry: user.c,
    language: getLanguageType(getLocale()),
    includeChildren: true,
  });

  const [contentUserFilter] = useState({
    parentContentUrl: url,
    includeSelections: true,
    isMyData: true,
  });

  // Hooks
  useEffect(() => {
    detail();
  }, [url]);

  // Functions
  const detail = async () => {
    setPageState((x) => ({ ...x, detail: false }));

    let [contentDetailResponse, contentUserListResponse] = await Promise.all([
      new Promise((resolve, reject) => {
        contentDetailGlobal(filter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
      new Promise((resolve, reject) => {
        contentUserDetail(contentUserFilter, (status, res) => {
          resolve(status ? res : null);
        });
      }),
    ]);

    if (!contentDetailResponse) {
      navigate('/content');
      return;
    }

    let result = contentDetailResponse.data;
    result.typeData = ContentTypes.find((x) => x.value === result.type);

    if (contentUserListResponse?.data) {
      result.selections = contentUserListResponse?.data?.selections;
    }

    view();

    setData(result);

    setPageState((x) => ({ ...x, detail: true }));
  };

  const view = async () => {
    await contentDetailView({ url, userCountry: user.c }, (_status, _res) => {});
  };

  // Components
  const Body = lazy(() => import(`../../components/pages/content/types/${data.typeData.name}`));

  const ContentTypeIcon = () => {
    switch (data.typeData.value) {
      case ContentType.Document:
        return <HiDocumentSearch />;
      case ContentType.Podcast:
        return <FaPodcast />;
      case ContentType.Course:
        return <BsQuestionDiamondFill />;
      case ContentType.Video:
        return <RiVideoFill />;
      case ContentType.Survey:
        return <RiSurveyFill />;

      case ContentType.Article:
      default:
        return <GrArticle />;
    }
  };

  // Render
  return (
    <Page
      title={data?.title}
      routes={[
        {
          path: `/contents`,
          name: t('Contents'),
          breadcrumbName: 'Contents',
          icon: <FaBookReader />,
        },
        {
          name: data?.title,
          breadcrumbName: 'ContentDetail',
          icon: data && ContentTypeIcon(),
        },
      ]}
    >
      <Card ready={pageState?.detail} templates={['page']}>
        {data && (
          <Suspense>
            <Body data={data} />
          </Suspense>
        )}

        {data?.children && <ContentItemList type={data.typeData.value} response={data.children} />}
      </Card>
    </Page>
  );
}
