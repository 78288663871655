import { Form } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserRoleType } from '../../../constants';
import VitalInputs from '../../../constants/inputs/VitalInputs';
import { vitalCreate, vitalCreateWithDelivery, vitalUpdate } from '../../../services/api';
import { notifySuccess } from '../../../utilies/notification';
import { Element } from '../../globals';

export default function VitalCreateUpdate({ isDelivery, isPreview, data, users, country, load }) {
  // Definitions
  const { t } = useTranslation();
  const [formRef] = Form.useForm();

  const [isAnyBleedingEpisodes, setIsAnyBleedingEpisodes] = useState(false);
  const [isAnyNewMedication, setIsAnyNewMedication] = useState(false);
  const [isAnyAdverseEvent, setIsAnyAdverseEvent] = useState(false);

  const [isCreate, setIsCreate] = useState(false);

  const [pageState, setPageState] = useState({ form: true });

  // Hooks
  useEffect(() => {
    if (data) {
      setIsAnyBleedingEpisodes(data.isAnyBleedingEpisodes);
      setIsAnyNewMedication(data.isAnyNewMedication);
      setIsAnyAdverseEvent(data.isAnyAdverseEvent);

      formRef.setFieldsValue(data);
    } else {
      formRef.resetFields();
    }

    setIsCreate(!data);
  }, [data]);

  // Events
  const onFinish = async (model) => {
    setPageState((x) => ({ ...x, form: false }));

    model = { ...model, country, userRole: UserRoleType.Patient };

    let request = isCreate ? (isDelivery ? vitalCreateWithDelivery : vitalCreate) : vitalUpdate;

    await request(model, (status, res) => {
      if (status) {
        load(model);
        notifySuccess(t(isCreate ? 'CreateCompleteSuccess' : 'UpdateCompleteSuccess'));
      }

      setPageState((x) => ({ ...x, form: true }));
    });
  };

  // Render
  return (
    <Element
      key="vital-form"
      onFinish={onFinish}
      ready={pageState.form}
      formRef={formRef}
      inputs={VitalInputs({
        isCreate,
        isPreview,
        users,
        isAnyBleedingEpisodes,
        onIsAnyBleedingEpisodesChange: ({ value }) => setIsAnyBleedingEpisodes(value),
        isAnyNewMedication,
        onIsAnyNewMedicationChange: ({ value }) => setIsAnyNewMedication(value),
        isAnyAdverseEvent,
        onIsAnyAdverseEventChange: ({ value }) => setIsAnyAdverseEvent(value),
      })}
      columnSize={24}
      submit={isPreview ? undefined : t('Confirm')}
    />
  );
}
