import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { deliveryStepUpdate } from '../../../../services/api';
import { notifySuccess } from '../../../../utilies/notification';
import { Button, Drawer } from '../../../globals';
import VitalCreateUpdate from '../../vital/VitalCreateUpdate';

export default function EnterPatientVitals({ next, country, delivery, step, complete, onError }) {
  // Definitions
  const { t } = useTranslation();

  const [panelVisibility, setPanelVisibility] = useState(false);

  const load = async (data) => {
    let model = { ...next, currentStepId: step.id, country, deliveryId: delivery.id, targetUserId: delivery.targetUserId, data: JSON.stringify(data) };

    await deliveryStepUpdate(model, (status, res) => {
      if (status) {
        notifySuccess(t('UpdateCompleteSuccess'));
        setPanelVisibility(true);
        complete();
      } else if (res?.data?.errors && onError) {
        onError(res.data.errors);
      }
    });
  };

  // Render
  return (
    step && (
      <Container>
        <Button templates={['colored']} block={true} onClick={() => setPanelVisibility(true)}>
          {t('EnterPatientVitals')}
        </Button>

        <Drawer visible={panelVisibility} onClose={() => setPanelVisibility(false)}>
          {panelVisibility && <VitalCreateUpdate userId={delivery.targetUserId} country={country} load={load} isDelivery={true} />}
        </Drawer>
      </Container>
    )
  );
}

const Container = styled.div``;
