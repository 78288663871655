import { CheckOutlined, CloudDownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsFileEarmarkMusicFill, BsFileEarmarkPdfFill, BsFillFileEarmarkPptFill } from 'react-icons/bs';
import { FaTrash } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi';
import { ImFilm } from 'react-icons/im';
import { RiFileExcel2Fill } from 'react-icons/ri';
import styled from 'styled-components';
import { PopupConfirm } from '../../globals';

export default function FileBoxBase({ file, type, onPreviewClick, onDownloadClick, onSelectClick, onDeleteClick }) {
  // Definitions
  const { t } = useTranslation();
  const [visibleAction, setVisibleAction] = useState(false);

  // Functions
  const Preview = ({ value }) => {
    switch (value.extension) {
      case 'pdf':
        return (
          <Icon>
            <BsFileEarmarkPdfFill />
          </Icon>
        );

      case 'xls':
      case 'xlsx':
        return (
          <Icon>
            <RiFileExcel2Fill />
          </Icon>
        );

      case 'doc':
      case 'docx':
        return (
          <Icon>
            <HiDocumentText />
          </Icon>
        );

      case 'ppt':
      case 'pptx':
        return (
          <Icon>
            <BsFillFileEarmarkPptFill />
          </Icon>
        );

      case 'mp4':
        return (
          <Icon>
            <ImFilm />
          </Icon>
        );

      case 'mp3':
      case 'wav':
      case 'wma':
      case 'aac':
      case 'flac':
      case 'm4a':
        return (
          <Icon>
            <BsFileEarmarkMusicFill />
          </Icon>
        );

      default:
        return <Image src={value.url} />;
    }
  };

  // Render
  return (
    <Container
      type={type}
      onMouseOver={() => setVisibleAction(true)}
      onMouseEnter={() => setVisibleAction(true)}
      onMouseOut={() => setVisibleAction(false)}
      onMouseLeave={() => setVisibleAction(false)}
    >
      <PreviewContainer>
        <Preview value={file} />
      </PreviewContainer>

      <NameContainer>
        <Extension>{file.extension}</Extension>
      </NameContainer>

      {visibleAction && (
        <Actions>
          {file.isImage
            ? onPreviewClick && <EyeOutlined onClick={() => onPreviewClick(file)} />
            : onPreviewClick && <CloudDownloadOutlined onClick={() => onDownloadClick(file)} />}

          {file.extension === 'pdf' && onPreviewClick && <EyeOutlined onClick={() => onPreviewClick(file)} />}

          {onSelectClick && <CheckOutlined onClick={() => onSelectClick(file)} />}

          {onDeleteClick && (
            <PopupConfirm title={t('AreYouSureWantToDelete')} onConfirm={() => onDeleteClick(file)} okText={t('Confirm')} cancelText={t('Cancel')}>
              <FaTrash />
            </PopupConfirm>
          )}
        </Actions>
      )}
    </Container>
  );
}

const Container = styled.div`
  width: calc(20% - 8px);
  height: 182px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid ${(x) => x.theme.colors.darkTurquoise};
  cursor: pointer;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
`;

const PreviewContainer = styled.div`
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Icon = styled.div`
  font-size: 100px;
  color: #1890ff;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  padding: 10px;
`;

const NameContainer = styled.div`
  height: 30px;
  background-color: ${(x) => x.theme.colors.white};
  width: 100%;
  padding: 5px;
  border-top: 1px solid ${(x) => x.theme.colors.darkTurquoise};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Extension = styled.span`
  text-transform: uppercase;
`;

const Actions = styled.div`
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: ${(x) => x.theme.colors.darkTurquoiseOpacity50};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: #fff;
  font-size: 35px;

  .anticon {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
