import { Button } from 'antd';
import styled, { css } from 'styled-components';

export default function ButtonBase({ disabled, templates, htmlType, ready, children, onClick, style, icon, href, block }) {
  return (
    <Container
      templates={templates}
      htmlType={htmlType}
      disabled={disabled ?? (ready !== undefined ? !ready : false)}
      loading={ready !== undefined ? !ready : false}
      onClick={onClick}
      style={style}
      icon={icon}
      href={href}
      block={block ?? false}
    >
      {children}
    </Container>
  );
}

const Primary = css`
  ${({ templates }) =>
    templates?.includes('primary') &&
    css`
      color: ${(x) => x.theme.colors.deepKaomaru};
      border: 1px dashed ${(x) => x.theme.colors.deepKaomaru};

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        border-color: ${(x) => x.theme.colors.deepKaomaru};
        background-color: ${(x) => x.theme.colors.deepKaomaru};
      }
    `}
`;

const White = css`
  ${({ templates }) =>
    templates?.includes('white') &&
    css`
      color: ${(x) => x.theme.colors.deepKaomaru};

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        background-color: ${(x) => x.theme.colors.deepKaomaru};
      }
    `}
`;

const RedColor = css`
  ${({ templates }) =>
    templates?.includes('red-color') &&
    css`
      color: ${(x) => x.theme.colors.rustyRed};
      border: 1px dashed ${(x) => x.theme.colors.rustyRed};

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        border-color: ${(x) => x.theme.colors.rustyRed};
        background-color: ${(x) => x.theme.colors.rustyRed};
      }
    `}
`;

const Colored = css`
  ${({ templates }) =>
    templates?.includes('colored') &&
    css`
      color: ${(x) => x.theme.colors.white};
      background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);

      span {
        color: ${(x) => x.theme.colors.white};
      }

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        border-color: transparent;
        color: ${(x) => x.theme.colors.white};
        background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
      }
    `}
`;

const Table = css`
  ${({ templates }) =>
    templates?.includes('table') &&
    css`
      color: ${(x) => x.theme.colors.white};
      background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
      border-radius: 10px;
      height: 40px;
      margin: 0px auto;
      line-height: 0px;

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        border-color: transparent;
        color: ${(x) => x.theme.colors.white};
        background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
        box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }
    `}
`;

const Navigation = css`
  ${({ templates }) =>
    templates?.includes('navigation') &&
    css`
      height: 40px;
      min-width: 40px;
      border-radius: ${(x) => x.theme.global.xxxlargeBorderRadius};
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 10px;
    `}
`;

const SubTitle = css`
  ${({ templates }) =>
    templates?.includes('sub-title', 'with-icon') &&
    css`
      padding: 0px;
      margin-left: 24px;
      color: ${(x) => x.theme.colors.darkTurquoise};
      box-shadow: none !important;

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    `}
`;

const Filter = css`
  ${({ templates }) =>
    templates?.includes('filter') &&
    css`
      width: 50px;
      height: 50px;
      border-radius: 10px;
      font-size: 20px;
      color: ${(x) => x.theme.colors.white};
      background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        background: linear-gradient(263deg, ${(x) => x.theme.colors.mediumSpringGreen} 0, ${(x) => x.theme.colors.darkTurquoise} 100%);
        box-shadow: 2px 2px 10px 0px ${(x) => x.theme.colors.lavenderGray} !important;
      }
    `}
`;

const Conference = css`
  ${({ templates }) =>
    templates?.includes('conference') &&
    css`
      width: 50px;
      height: 50px;
      border-radius: 50px;
      font-size: 20px;
      line-height: 0;

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.deepKaomaru};
        background: ${(x) => x.theme.colors.white};
        box-shadow: none !important;
      }
    `}

  ${({ templates }) =>
    templates?.includes('conference') &&
    templates?.includes('leave') &&
    css`
      color: ${(x) => x.theme.colors.white};
      background: ${(x) => x.theme.colors.rustyRed};

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        background: ${(x) => x.theme.colors.rustyRed};
        box-shadow: none !important;
      }
    `}

    
  ${({ templates }) =>
    templates?.includes('conference') &&
    templates?.includes('close') &&
    css`
      color: ${(x) => x.theme.colors.white};
      background: ${(x) => x.theme.colors.rustyRed};

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.white};
        background: ${(x) => x.theme.colors.rustyRed};
        box-shadow: none !important;
      }
    `}
`;

const SubTextTitle = css`
  ${({ templates }) =>
    templates?.includes('sub-text-title') &&
    css`
      padding: 0px;
      color: ${(x) => x.theme.colors.darkTurquoise};
      box-shadow: none !important;

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    `}
`;

const WhiteFullWidth = css`
  ${({ templates }) =>
    templates?.includes('white-full-width') &&
    css`
      display: flex;
      align-items: center;
      justify-content: flex-start;

      padding: 12px;
      margin: 8px 0px;
      border: 1px solid ${(x) => x.theme.colors.darkTurquoiseOpacity20};
      border-radius: ${(x) => x.theme.global.borderRadius};
      transition: all 0.3s;
      cursor: pointer;
      width: 100%;

      &[disabled],
      &[disabled]:active,
      &[disabled]:focus,
      &[disabled]:hover,
      &:focus,
      &:hover,
      &:active {
        color: ${(x) => x.theme.colors.mediumSpringGreen};
        transform: translateY(-1px);
        box-shadow: 2px 2px 6px 0px ${(x) => x.theme.colors.lavenderGray} !important;
        border: 1px solid ${(x) => x.theme.colors.darkTurquoiseOpacity20};
      }
    `}
`;

const HeaderIcon = css`
  ${({ templates }) =>
    templates?.includes('header-icon') &&
    css`
      @media only screen and (max-width: 600px) {
        span:last-child {
          display: none;
        }
      }
    `}
`;

const TreatmentUsageIcon = css`
  ${({ templates }) =>
    templates?.includes('usage-icon') &&
    css`
      width: 30px;
      height: 30px;
      padding: 0px;
      font-size: 12px;
      position: absolute;
      z-index: 999;
      right: 8px;
      top: 50%;
      transform: translateY(-50%);
    `}

  ${({ templates }) =>
    templates?.includes('usage-icon-second') &&
    css`
      right: 46px;
    `}
`;

const CalendarIcon = css`
  ${({ templates }) =>
    templates?.includes('calendar-icon') &&
    css`
      width: 50px;
      height: 50px;
      font-size: 20px;
      padding: 0px;

      @media only screen and (max-width: 1200px) {
        width: 40px;
        height: 40px;
      }
    `}
`;
const DeliveryStepButton = css`
  ${({ templates }) =>
    templates?.includes('delivery-step-button') &&
    css`
      height: 120px;
      padding-right: 30px;
      font-size: 20px;
      text-transform: none;

      > svg:first-child,
      .ant-btn-loading-icon {
        font-size: 60px;
        margin-right: 20px;
      }

      > svg:last-child {
        position: absolute;
        right: 0px;
        font-size: 40px;
        color: ${(x) => x.theme.colors.mediumSpringGreen};
      }
    `}
`;

const Container = styled(Button)`
  height: 50px;
  border-radius: ${(x) => x.theme.global.xxxlargeBorderRadius};
  font-weight: bold;
  text-transform: uppercase;
  border: 0px;
  display: flex;
  align-items: center;
  justify-content: center;

  &[disabled] {
    opacity: 0.5;
  }

  ${({ templates }) =>
    templates?.includes('with-icon') &&
    css`
      svg {
        margin-right: 8px;
      }
    `}

  ${White}
  ${Primary}
  ${RedColor}
  ${Colored}
  ${Navigation}
  ${Table}
  ${SubTitle}
  ${Filter}
  ${Conference}
  ${SubTextTitle}
  ${WhiteFullWidth}
  ${HeaderIcon}
  ${TreatmentUsageIcon}
  ${CalendarIcon}
  ${DeliveryStepButton}
  
  ${({ templates }) =>
    templates?.includes('mt-24') &&
    css`
      margin-top: 24px;
    `}
  
    ${({ templates }) =>
    templates?.includes('mb-24') &&
    css`
      margin-bottom: 24px;
    `}
    
    ${({ templates }) =>
    templates?.includes('full-width') &&
    css`
      width: 100% !important;
    `}
    
    ${({ templates }) =>
    templates?.includes('width-120') &&
    css`
      width: 120px !important;
    `}
    
    ${({ templates }) =>
    templates?.includes('absolute-bottom') &&
    css`
      position: absolute;
      bottom: 0px;
    `}
    
  ${({ templates }) =>
    templates?.includes('vams-score') &&
    css`
      width: calc(100% - 12px);
    `}
`;
